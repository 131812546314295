import { styled, useTheme } from "@mui/material/styles";
import Carousel, { CarouselProps } from "nuka-carousel";
import { ForwardedRef, forwardRef, useId } from "react";

import { AssetUrl } from "@holibob-packages/vault";

import { CSSVariable } from "../utils/CSSVariable";
import { HbmlChildrenWrapperOverlay } from "./HbmlComponents/common/HbmlChildrenWrapperOverlay";
import { HeroImagePicture } from "./HeroImage";
import { HeroImageWrapper } from "./HeroImageWrapper";
import { Typography, TypographyProps } from "./Typography";

export type HeroImagesProps = {
    height?: number;
    images: string[];
    mediumScreenHeight?: number;
    smallScreenHeight?: number;
    alt?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DOTS_CONTAINER_CLASS = "hero-images-dots-container";

function isLocalImage(src: string) {
    return src.startsWith("/");
}

const DEFAULT_DESKTOP_HEIGHT = 400;
const DEFAULT_MOBILE_HEIGHT = 280;

const SMALL_SCREEN_HEIGHT_VARIABLE = new CSSVariable("--hero-images-small-screen-height");
const MEDIUM_SCREEN_HEIGHT_VARIABLE = new CSSVariable("--hero-images-medium-screen-height");

const Wrapper = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    maxWidth: "100vw",
    height: SMALL_SCREEN_HEIGHT_VARIABLE.reference,

    [theme.breakpoints.up("md")]: {
        height: MEDIUM_SCREEN_HEIGHT_VARIABLE.reference,
    },
    [`.${DOTS_CONTAINER_CLASS}`]: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderRadius: 20,
        transform: "translateY(-6px)",

        "& li": {
            display: "inline-flex",
        },
        "& svg": {
            transform: "translateY(-1px)",
        },
    },
    ".slide.slide-current.slide-visible": {
        textAlign: "end",
    },
    ".slider-list": {
        alignItems: "end",
    },
    img: {
        color: "rgb(0,0,0,0.2)",
    },
}));

function srcToFinalSRC(src: string): string | AssetUrl | undefined {
    if (isLocalImage(src)) {
        return src;
    }

    const assetUrl = AssetUrl.enforceUrlForIdOrUrl(src, { type: "hbmlImage" });
    if (assetUrl.isLeft()) {
        return;
    }

    return assetUrl.unwrap();
}

export function HeroImages({
    images,
    children,
    mediumScreenHeight = DEFAULT_DESKTOP_HEIGHT,
    smallScreenHeight = DEFAULT_MOBILE_HEIGHT,
    style,
    alt,
    ...props
}: HeroImagesProps) {
    const id = useId();
    const renderedImages = images.flatMap((src, index) => {
        const finalSrc = srcToFinalSRC(src);

        if (typeof finalSrc === "string") {
            return (
                <HeroImageWrapper
                    data-testid="heroCarouselImage"
                    key={finalSrc}
                    alt={alt}
                    mediumScreenHeight={mediumScreenHeight}
                    smallScreenHeight={smallScreenHeight}
                    src={finalSrc}
                />
            );
        } else if (finalSrc instanceof AssetUrl) {
            return [
                <HeroImagePicture
                    data-testid="heroCarouselImage"
                    key={id}
                    alt={alt ?? ""}
                    mediumScreenHeight={mediumScreenHeight}
                    smallScreenHeight={smallScreenHeight}
                    src={finalSrc}
                    loading={index === 0 ? "eager" : "lazy"}
                />,
            ];
        }
        return [];
    });

    const theme = useTheme();

    const finalStyle = {
        ...style,
        [SMALL_SCREEN_HEIGHT_VARIABLE.toString()]: smallScreenHeight + "px",
        [MEDIUM_SCREEN_HEIGHT_VARIABLE.toString()]: mediumScreenHeight + "px",
    };
    const carouselProps: CarouselProps = {};
    if (images.length === 1) {
        carouselProps.renderBottomCenterControls = null;
    }

    return (
        <Wrapper style={finalStyle} data-testid="heroCarousel" {...props}>
            <Carousel
                wrapAround
                speed={400}
                autoplay={images.length > 1}
                autoplayInterval={5000}
                dragging={false}
                swiping={false}
                renderCenterLeftControls={null}
                renderCenterRightControls={null}
                defaultControlsConfig={{
                    pagingDotsContainerClassName: DOTS_CONTAINER_CLASS,
                    pagingDotsStyle: {
                        fill: theme.palette.primary.main,
                        outline: "none",
                        width: 20,
                    },
                }}
                {...carouselProps}
            >
                {renderedImages}
            </Carousel>
            <HbmlChildrenWrapperOverlay>{children}</HbmlChildrenWrapperOverlay>
        </Wrapper>
    );
}

const StyledTypography = styled(Typography)({
    textShadow: "0 2px 5px rgba(0,0,0, 0.6)",
    margin: "unset",
    hyphens: "auto",
    wordBreak: "break-word",
    // this is needed to override line height defined in child element.
    // Sometimes people make big title bolded which causes to render bolded text with different line-height
    "&& *": {
        lineHeight: "inherit",
    },
});

export type HeroImagesTypographyProps = Omit<TypographyProps, "variant" | "size">;

export const HeroImagesTitle = forwardRef(function HeroImagesTitleInternal(
    props: HeroImagesTypographyProps,
    ref: ForwardedRef<HTMLElement>
) {
    return (
        <StyledTypography
            variant="display"
            size="medium"
            color="light"
            component="h1"
            isResponsive={true}
            {...props}
            ref={ref}
        />
    );
});

export const HeroImagesSubtitle = forwardRef(function HeroImagesSubtitleInternal(
    props: HeroImagesTypographyProps,
    ref: ForwardedRef<HTMLElement>
) {
    return (
        <StyledTypography
            variant="title"
            size="large"
            color="light"
            component="h2"
            isResponsive={true}
            {...props}
            ref={ref}
        />
    );
});

export const HeroImagesDescription = forwardRef(function HeroImagesDescriptionInternal(
    props: HeroImagesTypographyProps,
    ref: ForwardedRef<HTMLElement>
) {
    return <StyledTypography variant="title" size="small" color="light" isResponsive={true} {...props} ref={ref} />;
});
